import {
  generateHSLRamp
} from 'rampensau/dist/index.mjs';
import seedrandom from 'seedrandom';

const rnd = seedrandom((Math.random() * 2999).toString());

const newOptions = () => {
  return {
    total: 10,
    hCenter: rnd() * 360,
    hCycles: .5 + rnd() * 1,
    sRange: [
      .2 + rnd() * .2,
      .15 + rnd() * .5
    ],
    lRange: [
      .05 + rnd() * .05,
      .90 + rnd() * .1
    ],
    sEasing: x => Math.pow(x, 2),
    lEasing: x => Math.pow(x, 1.25),
  }
}


const newColors = () => {
  const $root = document.documentElement;
  const options = newOptions();
  console.table(options)
  const hslRamp = generateHSLRamp(options);
  const cssHSL = hslRamp.map(color => `hsl(${color[0]}, ${color[1] * 100}%, ${color[2] * 100}%)`);
  cssHSL.forEach((color, i) => {
    $root.style.setProperty(`--c${i}`, color);
  });
$root.style.setProperty(`--c-gradient`, cssHSL.join());
};


function createSizeVars(newSizes) {
  const $root = document.documentElement;
  newSizes.forEach((size, i) => {
    $root.style.setProperty(`--size-${i}`, size);
  });
}

const sizes = [
  1,
  1,
  2,
  3,
  5,
  8,
  13,
  21,
  34,
  55,
  89
];

createSizeVars(sizes);
newColors();

export function initDesignSystem () {
  createSizeVars(sizes);
  newColors();
}