import {
  initDesignSystem,
} from './design';
import {
  createClock,
  setClock
} from './watch';

/**
 * Detect if the browser is Safari
 * and set a class on the html tag
 */
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
if (isSafari) {
  document.documentElement.classList.add('is-safari');
}

/**
 * Detext if the browser is Firefox
 * and set a class on the html tag
 */
const isFirefox = navigator.userAgent.includes('Firefox');
if (isFirefox) {
  document.documentElement.classList.add('is-firefox');
}


const $keyVisual = document.querySelector('[data-keyvisual]')

initDesignSystem();
setClock();
setInterval(() => setClock(), 1000);

$keyVisual.appendChild(createClock());

const $keyVisualTarget = document.querySelector('[data-keyvisualtarget]');

const $clock = document.querySelector('.clock');
const $logo = document.querySelector('[data-logo]');

function updateCoords () {
  const sourceBox = $keyVisual.getBoundingClientRect();
  const targetBox = $keyVisualTarget.getBoundingClientRect();

  const targetScale = targetBox.height / sourceBox.height;
  const targetXDiff = (targetBox.left - sourceBox.left);
  const targetYDiff = (targetBox.top - sourceBox.top);
  const relativeTargetX = targetXDiff / sourceBox.width;
  const relativeTargetY = targetYDiff / sourceBox.height;

  document.documentElement.style.setProperty('--targetScale', targetScale);
  document.documentElement.style.setProperty('--targetXDiff', targetXDiff);
  document.documentElement.style.setProperty('--targetYDiff', targetYDiff);
  document.documentElement.style.setProperty('--targetTargetX', relativeTargetX);
  document.documentElement.style.setProperty('--targetTargetY', relativeTargetY);

  // safari does not not want to update css custom properties within keyframe 
  // animations after the inital render.
  if (isSafari) {
    const $style = document.createElement('style');
    $style.type = 'text/css';
    const uiid = `${Math.floor(Math.random() * 1000000)}`;
    $style.innerHTML = `
    @keyframes moveDown${uiid} {
      100% {
        transform: translate(-50%, -50%) translate(calc(var(--targetXDiff) * 1px), calc(var(--targetYDiff) * 1px)) scale(var(--targetScale));
      }
    }
    `;
    document.getElementsByTagName('head')[0].appendChild($style);
    $clock.style.setProperty('animation', `moveDown${uiid} 10s paused forwards`);
    $clock.style.setProperty('animation-delay', 'calc(var(--relativeScroll) * -10s)');
  }
  //  console.log(targetScale, relativeTargetX, relativeTargetY);
}

$keyVisual.addEventListener('click', (e) => {
  initDesignSystem();
});

updateCoords();

setTimeout(() => {
  updateCoords();
}, 1500);

window.addEventListener('resize', updateCoords);

function updateAnimation(lastKnownScrollPosition, relativeScroll) {

}

let lastKnownScrollPosition = window.scrollY;
let ticking = false;

window.addEventListener('scroll', (e) => {
  lastKnownScrollPosition = window.scrollY;
  const relativeScroll = lastKnownScrollPosition / window.innerHeight;

  if (!ticking) {
    window.requestAnimationFrame(function () {
      updateAnimation(lastKnownScrollPosition, relativeScroll);
      document.documentElement.style.setProperty('--relativeScroll', relativeScroll);
      ticking = false;

      if (relativeScroll > 1) {
        document.documentElement.classList.add('is-intro-done');
      } else {
        document.documentElement.classList.remove('is-intro-done');
      }
    });

    ticking = true;
  }
});

document.documentElement.addEventListener('click', (e) => {
  if (e.target.matches('[data-entrylink]') || e.target.closest('[data-entrylink]')) {
    e.preventDefault();

    const $entryLink = e.target.closest('[data-entrylink]') || e.target;
    const targetId = $entryLink.getAttribute('href');
    const $detail = document.querySelector(targetId);
    const $target = document.querySelector('[data-entrydetailtarget]');

    history.replaceState(undefined, undefined, `${targetId}`);
      
    $target.innerHTML = '';

    if ( $entryLink.classList.contains('is-active') ) {
      $entryLink.classList.remove('is-active');
      $target.classList.remove('is-active');
    } else {
      const $clone = $detail.cloneNode(true);
      
      $clone.classList.add('is-visible');
      $entryLink.classList.add('is-active');

      $target.appendChild($clone);
      $target.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  if (e.target.matches('[data-toggle]') || e.target.closest('[data-toggle]')) {
    e.preventDefault();
    const $toggler = e.target.closest('[data-entrylink]') || e.target;
    const targetId = $toggler.getAttribute('aria-controls');
    const $detail = document.querySelector(`#` + targetId);

    if ( $toggler.getAttribute('aria-expanded') === 'true' ) {
      $toggler.setAttribute('aria-expanded', 'false');
      $detail.setAttribute('hidden', 'true');
    } else {
      $toggler.setAttribute('aria-expanded', 'true');
      $detail.removeAttribute('hidden');
    }
  }
  //[data-entrylink]
}, {capture: true});

document.documentElement.addEventListener('click', (e) => {
  if (e.target.matches('[data-entrydetailclose]') || e.target.closest('[data-entrydetailclose]')) {
    document.querySelector('.is-visible[data-entrydetail]').classList.remove('is-visible');
    document.querySelector('.is-active[data-entrylink]').classList.remove('is-active');
    history.replaceState(undefined, undefined, '#');
  }
}, {capture: true});


const setThingsOnElements = (
  $tpl, elementSector, attr, content
) => {
  if (!content) {
    return $tpl.querySelectorAll(elementSector).forEach($e => {
      if ($e.hasAttribute('data-parent')) {
        $e.parentNode.remove();
      } else {
        $e.remove();
      }
    });
  }
  if (Array.isArray(content)) {
    content = content.join(', ');
  }
  return Array.from(
    $tpl.querySelectorAll(elementSector)
  ).forEach(
    $el => attr === 'innerHTML' ? 
      $el.innerHTML = content : 
      $el.setAttribute(attr, content)
  );
}

let $eventTemplate = document.querySelector('[data-template="entry"]');

const aggregateEvent = (event) => {
  const $tpl = $eventTemplate.cloneNode(true).content;

  setThingsOnElements($tpl, '[data-entrylink]', 'href', `#${event.url}`);
  setThingsOnElements($tpl, '[data-entrydetail]', 'id', event.url);
  setThingsOnElements($tpl, '[data-entrytitle]', 'innerHTML', event.title);
  setThingsOnElements($tpl, '[data-entrycategory]', 'innerHTML', event.kind);
  setThingsOnElements($tpl, '[data-entrybody]', 'innerHTML', event.description);
  setThingsOnElements($tpl, '[data-entryimage]', 'src', event.image);
  setThingsOnElements($tpl, '[data-entryimage]', 'alt', 'image for ' + event.title);
  setThingsOnElements($tpl, '[data-entrytagline]', 'innerHTML', event.tagline);
  setThingsOnElements($tpl, '[data-entrylanguage]', 'innerHTML', event.language);
  setThingsOnElements($tpl, '[data-entryfield]', 'innerHTML', event.fields);
  setThingsOnElements($tpl, '[data-entryorganizer]', 'innerHTML', event.organizer);
  setThingsOnElements($tpl, '[data-entryaddress]', 'innerHTML', event.address);
  setThingsOnElements($tpl, '[data-entryspeaker]', 'innerHTML', event.speakers);
  setThingsOnElements($tpl, '[data-entrycoasts]', 'innerHTML', event.coasts);
  setThingsOnElements($tpl, '[data-entryregistration]', 'href', event.registration);
  setThingsOnElements($tpl, '[data-entryregistration]', 'innerHTML', event.registration);

  setThingsOnElements($tpl, '[data-entrydate]', 'datetime', event.starttime);
  
  if (event.datetext) {
    setThingsOnElements($tpl, '[data-entrydatestart]', 'innerHTML', event.datetext);
  } else {
    setThingsOnElements($tpl, '[data-entrydatestart]', 'innerHTML', event.startTimeFormated);
  }
  return $tpl;
}

const listEvents = (eventsObj) => {
  const dateOptions = {
    //weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric', 
    //timeStyle: 'short'
  };

  Object.keys(eventsObj).forEach((key) => {

    const startDate = new Date(eventsObj[key].starttime);

    const endDate = eventsObj[key].endtime ? new Date(eventsObj[key].endtime) : null;

    const startDateFormated = new Intl.DateTimeFormat('de-DE', {
      timeStyle: 'short',
      dateStyle: 'long',
    }).format(startDate).replace(' um', '');

    let endDateFormated;

    if (endDate) {
      endDateFormated = new Intl.DateTimeFormat('de-DE', {
        timeStyle: 'short',
        dateStyle: 'long',
      }).format(endDate).replace(' um', '');
    }

    eventsObj[key].uuid = key;
    eventsObj[key].startTimeDate = new Date(eventsObj[key].starttime);

    eventsObj[key].endTimeDate = new Date(eventsObj[key].endtime);
    eventsObj[key].startTimeFormated = startDateFormated;
    if (endDate) {
      eventsObj[key].endTimeFormated = endDateFormated;
    }
  });

  let eventsArr = Object.values(eventsObj);
  eventsArr.sort((a, b) => {
    return b.startTimeDate - a.startTimeDate;
  });

  const $eventList = document.querySelector('[data-eventlist]');

  eventsArr.forEach(
    event => {
      if (event.ispublished) { 
        $eventList.appendChild(
          aggregateEvent(event)
        )
      }
    }
  );
}

const feedGlobals = (globals) => {
  const $b = document.querySelector('body');
  setThingsOnElements($b, '[data-contactemail]', 'href', `mailto:${globals.contactemail}`);
  setThingsOnElements($b, '[data-instagramlink]', 'href', globals.instagramlink);
  setThingsOnElements($b, '[data-aboutfooter]', 'innerHTML', globals.aboutfooter);
  setThingsOnElements($b, '[data-welcometext]', 'innerHTML', globals.welcometext);
  setThingsOnElements($b, '[data-engagetext]', 'innerHTML', globals.engagetext);
  
  setThingsOnElements($b, '[data-bannertexttext]', 'innerHTML', globals.bannertexttext);
  setThingsOnElements($b, '[data-impressumtext]', 'innerHTML', globals.impressumtext);
}

const fetchData = () => {
  fetch('/data.json').then(d => d.json()).then(
    (data) => {
      listEvents(data.events);
      feedGlobals(data.globals.globals);
    }
  );
}

fetchData();


const isInputValid = ($input) => {
  return $input.checkValidity();
}

function toggleSendButton($form, $input) {
  if (isInputValid($input)) {
    $form.classList.add('is-valid');
  } else {
    $form.classList.remove('is-valid');
  }
};

let keyupTimer = null;

function newsLetterForm () {
  const $form = document.querySelector('[data-newsletterform]');
  const $input = document.querySelector('[data-newsletterinput]');
  
  toggleSendButton($form, $input);

  $input.addEventListener('keyup', (e) => {
    clearTimeout(keyupTimer);
    keyupTimer = setTimeout(() => {
      toggleSendButton($form, $input);
    }, 500);
  });
}

setTimeout(() => {
  if (window.location.hash && window.location.hash.length > 1) {
    const $entry = document.querySelector(`[href="${window.location.hash}"]`);
    if ($entry) {
      $entry.click();
    }
  }
}, 500);


//newsLetterForm();