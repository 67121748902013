export function createClock() {
  const $d = document.createElement('div');
  $d.classList.add('clock');

  $d.innerHTML = `
   <div class="clock__part clock__h"></div>
   <div class="clock__part clock__m"></div>
   <div class="clock__part clock__s"></div>
  `;

  return $d;
}

export function setClock() {
  const date = new Date();
  const s = date.getSeconds();
  const m = date.getMinutes();
  const h = date.getHours();

  document.documentElement.style.setProperty('--ah', h * 30 + m * .5);
  document.documentElement.style.setProperty('--am', m * 6);
  document.documentElement.style.setProperty('--as', s * 6);
}